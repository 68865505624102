export const environment = {
  production: false,
  api: 'https://api.qa.mercadomeet.com.co',
  mercadoWeb: 'https://qa.mercadomeet.com.co',
  mercadoWebAr: 'https://mercadomeet.com.ar/',
  webGame: 'https://qa.meetcoin.cash',
  appName: 'MEET-GAME',
  maxNumberCanSelected: 9,
  initLives: 0,
  initTries: 0,
  maxTries: 10
};
